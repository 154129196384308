import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  DestroyRef,
  HostBinding,
  inject,
  Input,
  QueryList,
} from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { NcpUiRouteStepperItemComponent } from '../ncp-ui-route-stepper-item/ncp-ui-route-stepper-item.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Breakpoint } from '@dm-workspace/core';
import { filter, startWith } from 'rxjs';
import { NcpBookingFacade } from '../../../../../ncp-booking/src/lib/services/ncp-booking-facade.service';

@Component({
  selector: 'dm-ncp-ui-route-stepper',
  templateUrl: './ncp-ui-route-stepper.component.html',
  styleUrls: ['./ncp-ui-route-stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: NcpUiRouteStepperComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NcpUiRouteStepperComponent extends CdkStepper implements AfterViewInit {
  #router = inject(Router);
  #aRoute = inject(ActivatedRoute);
  #cd = inject(ChangeDetectorRef);
  #destroyRef = inject(DestroyRef);
  showStepValidation = inject(NcpBookingFacade).showStepValidation;
  @ContentChildren(NcpUiRouteStepperItemComponent)
  protected stepsCopy: QueryList<NcpUiRouteStepperItemComponent>;
  override get selected(): NcpUiRouteStepperItemComponent {
    return super.selected as NcpUiRouteStepperItemComponent;
  }
  @HostBinding('class.disabled')
  @Input()
  protected disabled: boolean;
  override next() {
    const step = this.stepsCopy.get(this.selectedIndex + 1);
    if (step && step.editable) {
      this.selectStep(step);
    }
  }
  override previous() {
    super.previous();
    this.selectStep(this.selected);
  }
  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.stepsCopy.changes.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => {
      this.#cd.markForCheck();
    });
    this.#router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        startWith({ url: this.#router.url }),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe((e: { url: string }) => {
        const stepItemIndex = Array.from(this.stepsCopy).findIndex((item) => e.url.includes(item.routerLink));
        this.showStepValidation.set(false);
        if (stepItemIndex >= 0) {
          this.stepsCopy.forEach((item, index) => {
            if (index < stepItemIndex) {
              item.completed = true;
            }
          });
          this.selectedIndex = stepItemIndex;
        }
      });
  }

  protected selectStepByIndex(index: number): void {
    if (this.disabled) {
      return;
    }
    this.selectedIndex = index;
  }

  protected readonly Breakpoint = Breakpoint;

  protected selectStep(step: NcpUiRouteStepperItemComponent) {
    if (!this.selected?.stepControl?.valid || !step.stepControl?.valid) this.showStepValidation.set(true);
    this.#router.navigate(['./', step.routerLink], {
      relativeTo: this.#aRoute,
    });
  }
}
