import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { DateRange, IDateRange } from '@dm-workspace/types';
import { DATE_FORMAT_PLACEHOLDER } from '@dm-workspace/shared';
import { Breakpoint } from '@dm-workspace/core';
import { addDays } from 'date-fns';
import { formatDate, parseDate } from '@dm-workspace/utils';

export type Chip = { value: number; label: string; labelValue: number };

@Component({
  selector: 'dm-ncp-calendar-range',
  templateUrl: './calendar-range.component.html',
  styleUrls: ['./calendar-range.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarRangeComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarRangeComponent implements ControlValueAccessor, OnInit {
  #calendar = inject(NgbCalendar);

  private onTouch: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onChange: (value: any) => void = () => ({});
  @ViewChild(NgbDatepicker) datePicker: NgbDatepicker;
  @Input() initialDateRange: IDateRange | null = null;
  @Input() maxDate!: NgbDateStruct;
  @Input() minDate!: NgbDateStruct;
  @Input() singleView = true;
  @Input() rangeMaxDays!: number;
  @Output() chipSelected = new EventEmitter<Chip>();
  @Output() dateSelected = new EventEmitter<Partial<DateRange>>();
  protected chips: Chip[] = [
    { value: 7, label: 'WEEK', labelValue: 1 },
    { value: 14, label: 'WEEKS', labelValue: 2 },
    { value: 30, label: 'MONTH', labelValue: 1 },
    { value: 90, label: 'MONTHS', labelValue: 3 },
    { value: 180, label: 'MONTHS', labelValue: 6 },
    { value: 365, label: 'YEAR', labelValue: 1 },
  ];
  protected selectedChip: Chip;
  protected maxDateRange: NgbDateStruct;
  public hoveredDate: NgbDate | null = null;
  public fromDate: NgbDate | NgbDateStruct | null = null;
  public toDate: NgbDate | NgbDateStruct | null = null;
  public datePlaceholderFormat = DATE_FORMAT_PLACEHOLDER;

  constructor(
    public adapter: NgbDateAdapter<string>,

    protected cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const { fromDate, toDate } = this.initialDateRange || {};
    if (fromDate && toDate) {
      this.fromDate = this.adapter.fromModel(this.initialDateRange.fromDate);
      this.toDate = this.adapter.fromModel(this.initialDateRange.toDate);
      this.emitDate();
    }
    this.setMaxDate();
  }

  onDateSelection(date: NgbDate) {
    this.selectedChip = undefined;
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.resetDate();
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.emitDate();
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.resetDate();
    }
    this.dateSelected.emit({
      toDate: this.adapter.toModel(this.toDate),
      fromDate: this.adapter.toModel(this.fromDate),
    });
    this.setMaxDate();
  }

  onMobileDateSelect($event: NgbDate, fromDate: boolean) {
    if (fromDate) {
      this.fromDate = $event;
    } else {
      this.toDate = $event;
    }
    if (this.fromDate && this.toDate) {
      this.emitDate();
    } else {
      this.onChange(null);
      this.onTouch();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(obj: DateRange): void {
    const { fromDate, toDate } = obj || {};
    if (fromDate && toDate) {
      this.fromDate = this.adapter.fromModel(fromDate);
      this.toDate = this.adapter.fromModel(toDate);
    } else {
      this.fromDate = null;
      this.toDate = null;
    }
    this.setMaxDate();
  }

  daysInMonth(month: number, year: number) {
    return new Date(year, month, 0).getDate();
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  isFirst(date: NgbDate) {
    return date.equals(this.fromDate) && !this.toDate && !date.before(this.hoveredDate);
  }

  isLast(date: NgbDate) {
    return date.equals(this.toDate) || date.day === this.daysInMonth(date.month, date.year);
  }

  emitDate(): void {
    if (!this.onChange) {
      return;
    }

    const fromDate = this.adapter.toModel(this.fromDate);
    const toDate = this.adapter.toModel(this.toDate);

    this.onChange({ fromDate, toDate });
    this.onTouch();
  }

  private resetDate(): void {
    this.onChange(null);
    this.onTouch();
  }
  setMaxDate() {
    if (this.maxDate) {
      this.maxDateRange = this.maxDate;
      return;
    }
    if (!this.fromDate || !this.rangeMaxDays || this.toDate) {
      this.maxDateRange = null;
      return;
    }
    const result = new Date(this.adapter.toModel(this.fromDate));
    result.setDate(result.getDate() + this.rangeMaxDays);
    this.maxDateRange = this.adapter.fromModel(result.toISOString());
    this.cd.detectChanges();
  }

  protected readonly Breakpoint = Breakpoint;

  nextMonth(month: NgbDate) {
    const date = this.#calendar.getNext(month, 'm', 1);
    this.datePicker.navigateTo(date);
  }
  prevMonth(month: NgbDate) {
    const date = this.#calendar.getPrev(month, 'm', 1);
    this.datePicker.navigateTo(date);
  }

  onSwipe(direction: 'right' | 'left', month: NgbDate) {
    if (!this.singleView) {
      return;
    }
    if (direction === 'left') {
      this.nextMonth(month);
    } else {
      this.prevMonth(month);
    }
  }

  selectDateFromChip(chip: Chip) {
    this.chipSelected.emit(chip);
    this.selectedChip = chip;
    if (!this.fromDate) {
      this.fromDate = this.#calendar.getToday();
    }
    const dateString = this.adapter.toModel(this.fromDate);
    const fromDate = parseDate(dateString);
    const newDate = addDays(fromDate, chip.value);
    this.toDate = this.adapter.fromModel(formatDate(newDate));
    this.datePicker.navigateTo(this.fromDate);
    this.emitDate();
  }
}
