import { Component, inject, OnInit, signal } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiValidatorService, emailLoginValidator } from '@dm-workspace/forms';
import { NotificationService } from '@dm-workspace/notification';
import { AuthService, FormView } from '@dm-workspace/core';
import { HttpErrorResponse } from '@angular/common/http';
import { IAuthLoginUserResponse } from '@dm-workspace/types';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';

type FormValue = {
  email: string;
  password: string;
};

@Component({
  selector: 'dm-ncp-auth-login',
  templateUrl: './ncp-auth-login.component.html',
  styleUrls: ['./ncp-auth-login.component.scss', '../ncp-auth-shared.component.scss'],
})
export class NcpAuthLoginComponent extends FormView<FormValue> implements OnInit {
  #fb = inject(FormBuilder);
  #notification = inject(NotificationService);
  #auth = inject(AuthService);
  #router = inject(Router);
  #aModal = inject(NgbActiveModal, {
    optional: true,
  });
  #location = inject(Location);
  public isModal = signal<boolean>(!!this.#aModal);
  public passwordResetSuccess: boolean;

  constructor(override readonly apiValidator: ApiValidatorService) {
    super(apiValidator);
    if (!this.#aModal) {
      this.passwordResetSuccess = this.#router.getCurrentNavigation()?.extras?.state?.['passwordResetSuccess'] || false;
      this.#location.replaceState('/auth/login', '', { passwordResetSuccess: false });
    }
  }

  ngOnInit(): void {
    this.form = this.#fb.group({
      email: new FormControl<string>('', [Validators.required, emailLoginValidator()]),
      password: new FormControl<string>('', [Validators.required]),
    });
  }

  override apiCall(params: FormValue): Observable<IAuthLoginUserResponse> {
    return this.#auth.login(params, !this.#aModal);
  }

  protected override onSuccess(res: unknown) {
    super.onSuccess(res);
    if (this.#aModal) {
      this.#aModal.close(true);
    } else {
      this.#router.navigate(['new', 'select-marina'], { state: { asGuest: false } });
    }
  }

  override onError(res: HttpErrorResponse) {
    super.onError(res);

    this.#notification.add({
      type: 'ncp_error',
      content: 'ERRORS.LOGIN_OR_PASSWORD_INCORRECT',
    });
  }

  continueAsGuest() {
    if (this.#aModal) {
      this.#aModal.close(false);
    } else {
      this.#router.navigate(['new', 'select-marina'], { state: { asGuest: true } });
    }
  }

  onForgotPasswordClick(): void {
    if (this.#aModal) {
      this.#aModal.close(false);
    }
  }
}
