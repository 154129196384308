<div class="calendar-range-datepicker-group" [class.multiple-view]="!singleView">
  <ngb-datepicker
    #dp
    (dateSelect)="onDateSelection($event)"
    [displayMonths]="singleView ? 1 : 2"
    [minDate]="minDate"
    [maxDate]="maxDateRange"
    [dayTemplate]="t"
    [contentTemplate]="c"
    outsideDays="hidden"
    class="ngb-datepicker-custom"
  />
  <ng-template #t let-date let-focused="focused">
    <span
      class="custom-day"
      [class.focused]="focused"
      [class.range]="isRange(date)"
      [class.faded]="isHovered(date) || isInside(date)"
      [class.first]="isFirst(date)"
      [class.last]="isLast(date)"
      (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null"
    >
      {{ date.day }}
    </span>
  </ng-template>
  <ng-template #c let-dp>
    <div class="month-grid">
      @for (month of dp.state.months; track month) {
        <div class="month">
          <div class="text-body-m fw-700 month-label">{{ dp.i18n.getMonthFullName(month.month) | uppercase | translate | titlecase }} {{ month.year }}</div>
          <ngb-datepicker-month
            class="border rounded"
            [month]="month"
            (swipeleft)="onSwipe('left', month)"
            (swiperight)="onSwipe('right', month)"
          />
        </div>
      }
    </div>
  </ng-template>
  <div class="chips">
    @for (chip of chips; track chip.value) {
      <button type="button" class="chip" (click)="selectDateFromChip(chip)" [class.selected]="selectedChip === chip">
        {{ chip.value }} {{ "NIGHTS" | translate }}
      </button>
    }
  </div>
</div>
