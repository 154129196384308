<div [ngClass]="isModal() ? 'popup-content' : 'view-content'">
  <h3 [ngClass]="isModal() ? 'text-left' : 'text-center'" class="title mb-4">{{ isModal() ? ("NCP_AUTH_MODULE.LOGIN.POPUP_LOGIN_HEADER" | translate | uppercase) : "LOGIN" | translate | uppercase }}</h3>
  @if (passwordResetSuccess) {
    <div class="subtitle text-center d-flex justify-content-center align-items-center mb-4">
      <i class="icon i-check-circle success"></i>
      <span class="success-text">{{ "NCP_AUTH_MODULE.LOGIN.PASSWORD_RESET_SUCCESS" | translate }}</span>
    </div>
  }
  <form *ngIf="form" class="text-left" [formGroup]="form" (ngSubmit)="onSubmit()">
    <dm-form-group class="mb-xl">
      <label for="email">{{ "E_MAIL" | translate }}</label>
      <input id="email" dmForm type="text" formControlName="email" [placeholder]="'ENTER_E_MAIL' | translate" />
      <dm-form-error-message [control]="form.get('email')" />
    </dm-form-group>
    <dm-form-group class="mb-4">
      <div class="d-flex justify-content-between py-3">
        <label for="password">{{ "PASSWORD" | translate }}</label>
        <a routerLink="/auth/password-lost" class="link link--color-primary" (click)="onForgotPasswordClick()">
          {{ "AUTH_MODULE.LOGIN.RESET_PASSWORD" | translate | uppercase }}
        </a>
      </div>
      <dm-form-password-toggle>
        <input id="password" dmForm formControlName="password" type="password" [placeholder]="'ENTER_PASSWORD' | translate" />
      </dm-form-password-toggle>
      <dm-form-error-message controlName="password" />
    </dm-form-group>

    <button class="btn btn-primary btn-full w-100" type="submit" [disabled]="pending">
      <dm-ui-spinner class="me-2" [showSpinner]="pending" />
      {{ "LOGIN" | translate }}
    </button>

    <div class="hr-sect">
      <span>{{ "OR" | translate | uppercase }}</span>
    </div>

    <button class="btn btn-outline w-100 border-secondary" type="button" [disabled]="pending" (click)="continueAsGuest()">
      <dm-ui-spinner class="me-2" [showSpinner]="pending" />
      {{ "NCP_AUTH_MODULE.LOGIN.CONTINUE_AS_GUEST" | translate | uppercase }}
    </button>
  </form>
</div>

