<div class="wrap">
  <div class="container">
    @if (Breakpoint.MOBILE | maxBreakpoint | async) {
      <div class="mx-2">
        <a href="https://www.d-marin.com/"><span class="logo i-logo"></span></a>
      </div>
    }

    @if (showingLoginButton()) {
      <div class="login-button-wrapper">
        @if (isLoggedIn$ | async) {
          <button type="button" (click)="onLogOutClick()">{{ "LOG_OUT" | translate }}</button>
        } @else {
          <button type="button" (click)="onLoginClick()">{{ "LOG_IN_ALT" | translate }}</button>
        }
      </div>
    } @else {
      <a class="link" href="https://www.d-marin.com/en/search/">{{ "EXPLORE_BY_MAP_LINK" | translate }}</a>
    }

    @if (Breakpoint.TABLET | minBreakpoint | async) {
      <div class="me-auto ms-auto">
        @if (showingLoginButton()) {
          <a href="https://www.d-marin.com/"><span class="logo i-logo"></span></a>
        }
      </div>
    }

    <div class="flag-button__wrapper">
      <div ngbDropdown class="d-inline-block">
        <button type="button" class="flag-btn btn d-flex align-items-center px-0" id="flag-dropdown" ngbDropdownToggle>
          @if (selectedLang) {
            <img [src]="selectedLang.code.split('-')[1] || selectedLang.code | uppercase | flag: 20" alt="flag" />
            <span class="flag-button__wrapper--text fw-500">{{
              selectedLang.name | uppercase | translate | titlecase
            }}</span>
          }
        </button>
        <div ngbDropdownMenu aria-labelledby="flag-dropdown">
          @for (lang of languages; track lang) {
            <button ngbDropdownItem type="button" class="w-100" (click)="changeLng(lang)">
              <div class="d-flex align-items-center">
                <img
                  [src]="
                    (lang.code.includes('-') ? (lang.code.split('-')[1] | uppercase) : (lang.code | uppercase))
                      | flag: 20
                  "
                  alt="flag"
                  class="mx-2"
                />
                <span class="fs-6 fw-500">{{ lang.name | translate | titlecase }}</span>
              </div>
            </button>
          }
        </div>
      </div>
    </div>
    @if (!showingLoginButton()) {
      <a class="link" href="https://www.d-marin.com/en/contact/">{{ "CONTACT_DMARIN_LINK" | translate }}</a>
    }
  </div>
</div>
