@if (Breakpoint.TABLET | minBreakpoint | async) {
  <dm-ncp-auth-route-navigation-header />
  <div class="container">
    @if (setPasswordParams() && this.userEmail$ | async) {
      <dm-ncp-auth-route-bubble-tooltip [userEmail]="this.userEmail$ | async" />
    }
    <div class="wrapper">
      <router-outlet />
    </div>
  </div>
} @else {
  <dm-ncp-auth-route-navigation-header />
  <div class="container">
    <div class="wrapper-mobile">
      @if (setPasswordParams() && this.userEmail$ | async) {
        <dm-ncp-auth-route-bubble-tooltip [userEmail]="this.userEmail$ | async" />
      }
      <router-outlet />
    </div>
  </div>
}
