import { computed, inject, Injectable, signal } from '@angular/core';
import { MarinasService } from '@dm-workspace/core';
import { first, map, switchMap, tap } from 'rxjs';
import { CMSSelectedMarina, LOCALIZATION_LANGUAGES } from '@dm-workspace/types';
import { CmsMarinasApiService } from '@dm-workspace/data-access';

@Injectable({
  providedIn: 'root',
})
export class NcpMarinasService {
  #marinasService = inject(MarinasService);
  #csMarinasApiService = inject(CmsMarinasApiService);
  selectedMarina = signal<undefined | CMSSelectedMarina>(undefined);
  marinaCoverUrl = computed(() => this.getMarinaCoverUrl(this.selectedMarina()));
  fetchAndSelectMarinaById(id: string, lng: LOCALIZATION_LANGUAGES) {
    return this.#csMarinasApiService.fetchMarina(id, lng).pipe(
      map((value) => value.data),
      switchMap((marinaCMS) =>
        this.#marinasService.marinas$.pipe(
          first(),
          map((marinas) => {
            const marinaOB = marinas.find(({ code }) => code === marinaCMS.attributes.code);
            if (!marinaOB) {
              throw Error('No marina match!');
            }
            return {
              marinaOB,
              marinaCMS,
            };
          })
        )
      ),
      tap((value) => this.selectedMarina.set(value))
    );
  }

  private getMarinaCoverUrl(selectedMarina: CMSSelectedMarina | undefined) {
    const marinaCmsGallery = selectedMarina?.marinaCMS?.attributes?.mediaThumbnails?.images.find(
      (image) => !!image?.file?.data?.attributes?.url
    );
    return (
      marinaCmsGallery?.file?.data?.attributes?.url || selectedMarina?.marinaOB.imageUrl || 'assets/default-marina.jpg'
    );
  }
}
