<h3 class="title mb-4 text-center">{{ "NCP_AUTH_MODULE.FORGOT_PASSWORD.HEADER" | translate | uppercase }}</h3>
<div class="text-center mb-4">
    <span>{{ "NCP_AUTH_MODULE.FORGOT_PASSWORD.SUBTITLE" | translate }}</span>
</div>

@if (passwordResetSentSuccess) {
  <div class="text-center d-flex justify-content-center align-items-center mb-4">
    <i class="icon i-check-circle success"></i>
    <span class="success-text">{{ "NCP_AUTH_MODULE.FORGOT_PASSWORD.SEND_LINK_SUCCESS" | translate }}</span>
  </div>
}

<form *ngIf="form" class="text-left" [formGroup]="form" (ngSubmit)="onSubmit()">
  <dm-form-group class="mb-4">
    <label>{{ "E_MAIL" | translate }}</label>
    <input dmForm type="text" formControlName="email" [placeholder]="'ENTER_E_MAIL' | translate" />
    <dm-form-error-message [control]="form.get('email')" />
  </dm-form-group>

  <button class="btn btn-primary btn-full w-100" type="submit" [disabled]="pending">
    <dm-ui-spinner class="me-2" [showSpinner]="pending" />
    {{ "NCP_AUTH_MODULE.FORGOT_PASSWORD.SEND_LINK" | translate }}
  </button>

  <div class="hr-sect">
    <span>{{ "OR" | translate | uppercase }}</span>
  </div>

  <button class="btn btn-outline-primary w-100" type="button" [disabled]="pending" routerLink="/login">
    <dm-ui-spinner class="me-2" [showSpinner]="pending" />
    {{ "NCP_AUTH_MODULE.FORGOT_PASSWORD.GO_BACK_TO_LOGIN" | translate | uppercase }}
  </button>
</form>
