<ng-container [formGroup]="form()" *ngIf="showForm()">
  <h2 class="my-4 text-header-xs text-header-md-m">{{ "TERMS_AND_CONDITIONS" | translate }}</h2>
  @for (term of terms | keyvalue; track term.value; let index = $index) {
    @if (form().controls[term.key]) {
      <dm-form-group class="mb-3 mt-md-4">
        <input type="checkbox" dmForm [formControl]="form().controls[term.key].controls.check" [id]="term.key" />
        <label [for]="term.key">
          {{ "I_AGREE_WITH" | translate }}
          <a class="link" [href]="term.value.url" target="_blank">{{ term.key | ncpAgreementsLink | translate }}</a>
        </label>
        <dm-form-error-message
          [control]="form().controls[term.key].controls.check"
          [required]="term.key | ncpAgreementsRequiredText | translate"
        />
      </dm-form-group>
    }
  }
</ng-container>
