@if (Breakpoint.DESKTOP | minBreakpoint | async) {
  <div class="breadcrumb-wrapper d-flex flex-column align-items-center">
    <nav class="navigation container" [ngClass]="{ navigation__wrapped: showStepValidation() }">
      @for (step of stepsCopy; track step.routerLink; let i = $index; let last = $last) {
        <a
          class="item"
          [class.completed]="step.completed"
          [class.disabled]="!step.editable"
          [class.error]="step.hasError"
          [attr.aria-current]="selectedIndex === i"
          (click)="selectStep(step)"
        >
          @if (step.completed && selectedIndex !== i) {
            <span class="icon i-check-circle"></span>
          } @else {
            <span class="icon {{ step.icon }}"></span>
          }
          <div class="item-content">
            <p class="step mb-1">{{ "STEP" | translate | uppercase }} {{ i + 1 }}</p>
            <p class="label">{{ step.label | uppercase }}</p>
          </div>
        </a>
        @if (!last) {
          <span class="space"></span>
        }
      }
    </nav>
    @if (showStepValidation()) {
      <dm-ncp-ui-step-error></dm-ncp-ui-step-error>
    }
  </div>
} @else {
  <div class="navigation-mobile">
    <div class="d-flex navigation-mobile-container">
      <div class="d-flex align-items-center">
        <span class="step-icon" [class]="selected?.icon"></span>
        <p class="ms-2 text-header-xs text-uppercase">{{ selected?.label }}</p>
      </div>

      <div class="d-flex align-items-center ms-auto">
        <dm-ncp-ui-route-stepper-counter [currentStep]="selectedIndex + 1" [steps]="steps.length" />
      </div>
    </div>
  </div>
}
