<div class="wrap">
  <div class="container">
    @if (showBackButton()) {
      <div class="d-flex align-items-center back-button">
        <button type="button" class="back-link" (click)="goBack()">
          <i class="i-chevron-left"></i>
          @if (Breakpoint.TABLET | minBreakpoint | async) {
            <p class="m-0">{{ "BACK" | translate | uppercase }}</p>
          }
        </button>
      </div>
    }
    <div class="logo-link">
      <a href="https://www.d-marin.com/"><span class="logo i-logo"></span></a>
    </div>
  </div>
</div>
